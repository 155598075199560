<template>
  <div class="card my-3">
    <div class="card-body bg-success text-white rounded">
      <h5 class="card-title">{{ questionParts.PartType }}</h5>
      <p class="card-text">{{ questionParts.Description }}</p>
    </div>
  </div>
        <div class="row">
            <div class="col-md-12">
                <span class="badge badge-dark bg-dark mr-8"  v-for="(word, qIndex) in questionParts.MatchWords" :key="qIndex">{{word}}</span>
            </div>
        </div>        
    <div class="card my-4" v-for="(question, qIndex) in questionParts.Questions" :key="qIndex">
    <div class="card-body border  rounded">
      <p class="card-text" v-if="question.QuestionContent !== undefined">
        <span class="bg-success text-white font-weight-bold py-1 px-2 mr-8">{{ qIndex + 1 }}.</span>
        {{ question.QuestionContent }}
      </p>
      <img class="img-responsive mb-4" v-if="question.Image != null" height="150" :src="question.Image">

      <div class="input-group input-group-sm border rounded">
        <div class="input-group-prepend">
          <span class="input-group-text bg-success text-white" id="inputGroup-sizing-sm"
            >Answer
          </span>
        </div>
        <input type="text" class="form-control" :ref="'question'+question.Id" :data-id="question.Id" />
      </div>      
    </div>
    </div>
</template>

<script>
export default {
  name: "WordMatchQuestion",
  components: {},
  props: {
    questionParts: {
      type: Object,
    },
  },
  mounted() {
  },
  methods: {},
};
</script>
