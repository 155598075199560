
<template>
  <div class="card my-3">
    <div class="card-body bg-success text-white rounded">
      <h5 class="card-title">{{ questionParts.PartType }}</h5>
      <p class="card-text">{{ questionParts.Description }}</p>
    </div>
  </div>
    <div class="card my-4">
    <div class="card-body border rounded">
        <div class="row no-gutters">
            <div class="col-md-4 mb-4" v-for="(question, qIndex) in questionParts.Questions" :key="qIndex">
                <img class="img-fluid"  :src="question.QuestionContent"><br>
                <div class="input-group input-group-sm border rounded mb-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text bg-success text-white" id="inputGroup-sizing-sm"
                        >{{qIndex+1}}
                        </span>
                    </div>
                    <input type="text" class="form-control"  maxlength="1" :ref="'question'+question.Id" :data-id="question.Id" />
                </div>            
            </div>
        </div>
        <div class="row no-gutters">
            <div class="col-md-12  mb-4">
                <span class="badge badge-dark bg-dark mr-8"  v-for="(word, qIndex) in questionParts.MatchWords" :key="qIndex">{{word}}</span>
            </div>
        </div>        
    </div>
  </div>

</template>

<script>
export default {
  name: "ImageMatchQuestion",
  components: {},
  props: {
    questionParts: {
      type: Object,
    },
  },
  mounted() {
  },
  methods: {},
};
</script>
