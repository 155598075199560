import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/login.vue"; 
import ExamParts from "../views/exam-parts";
const routes = [
  {
    path: "/",
    name: "Login",
    component: Login
  },
  {
    path: "/exam-parts",
    name: "ExamParts",
    component: ExamParts,
    children: [
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode : 'abstract'
});

export default router;
