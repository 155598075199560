<template>
  <div v-if="currentStep === 0 && isComplete===false && questionParts.length > 0">
    <IntroExam v-bind:questionParts="questionParts"></IntroExam>
    <div class="row mx-2">
      <div class="col-lg-12">
        <button class="btn btn-success float-end" v-on:click="setCurrentStep(1)">
          Let's Start!
        </button>
      </div>
    </div>
  </div>

  <div v-if="currentStep > 0 && isComplete===false">
    <div class="row my-4 mx-2">
      <div class="col-lg-12 px-0">
        <CustomText tag="h3" class="text-success font-weight-bold my-0"
          >PART {{ currentStep }}</CustomText
        >
      </div>
    </div>
    <div class="row mb-3 mx-2 border border-success rounded">
      <div class="col-lg-12 py-2 px-4 min-h-780">
        <component :is="selectedComponent" v-bind:questionParts="questionParts[currentStep-1]" ref="questionReference" :key="NaN"></component>
      </div>
    </div>
    <div class="row mx-2">
      <div class="col-lg-12 px-0">
        <button
          class="btn btn-success float-start"
          v-if="currentStep <= lastStep && currentStep > 1"
          v-on:click="setCurrentStep(currentStep - 1)"
        >
          Part {{ currentStep - 1 }}
          <font-awesome-icon icon="arrow-alt-circle-left" class="mr-10" />
        </button>        
        <button
          class="btn btn-success float-end"
          v-if="currentStep < lastStep && currentStep != 0"
          v-on:click="setCurrentStep(currentStep + 1)"
        >
          Part {{ currentStep + 1 }}
          <font-awesome-icon icon="arrow-alt-circle-right" class="ml-10" />
        </button>

        <button
          class="btn btn-success float-end"
          v-if="currentStep === lastStep"
          v-on:click="completeExam()"
        >
          Go To Result
          <font-awesome-icon icon="check-circle" class="ml-10" />
        </button>
      </div>
    </div>
  </div>
  
  <div v-if="isComplete === true">
    <ExamComplete></ExamComplete>
  </div>
</template>

<script>
import CustomText from "@/components/CustomText";

import IntroExam from "@/components/IntroExam";
import MultiChoiceQuestion from "@/components/MultiChoiceQuestion";
import FillBlankQuestion from "@/components/FillBlankQuestion";
import ListeningQuestion from "@/components/ListeningQuestion";
import ExamComplete from "@/components/ExamComplete";
import axios from "axios";
import ParagraphQuestion from '../components/ParagraphQuestion.vue';
import ImageMatchQuestion from '../components/ImageMatchQuestion.vue';
import WordMatchQuestion from '../components/WordMatchQuestion.vue';

export default {
  name: "ExamParts",
  components: {
    CustomText,
    "multichoice-part" : MultiChoiceQuestion,
    "fillblank-part" : FillBlankQuestion,
    "listening-part" : ListeningQuestion,
    "paragraph-part" : ParagraphQuestion,
    "imagematch-part" : ImageMatchQuestion,
    "wordmatch-part" : WordMatchQuestion,
    ExamComplete,
    IntroExam,
  },
  data: function () {
    return {
      selectedComponent : "",
      answers: [],
      currentStep: 0,
      isTest : JSON.parse(localStorage.userData).isTest,
      questionParts: [],
      lastStep: 1,
      isComplete : false,
      QuestionTypes: Object.freeze({
        MULTI_CHOICES: 1,
        FILL_IN_THE_BLANK: 2,
        LISTENING: 3,
        PARAGRAPH : 4,
        IMAGE_MATCH : 5,
        WORD_MATCH : 6
      }),
    };
  },
  async mounted() {
    this.checkUserLogged()
    
    await this.getQuestions(); 
  },
  methods: {
    async setCurrentStep(step) {
      if (!this.currentStepValidation()) return;

      await this.saveAnswers();

      if (step <= this.lastStep) {
        this.currentStep = step;

        if(this.questionParts[this.currentStep-1].QuestionType === this.QuestionTypes.LISTENING) this.selectedComponent = "listening-part";
        if(this.questionParts[this.currentStep-1].QuestionType === this.QuestionTypes.FILL_IN_THE_BLANK) this.selectedComponent = "fillblank-part";
        if(this.questionParts[this.currentStep-1].QuestionType === this.QuestionTypes.MULTI_CHOICES) this.selectedComponent = "multichoice-part";          
        if(this.questionParts[this.currentStep-1].QuestionType === this.QuestionTypes.PARAGRAPH) this.selectedComponent = "paragraph-part";          
        if(this.questionParts[this.currentStep-1].QuestionType === this.QuestionTypes.IMAGE_MATCH) this.selectedComponent = "imagematch-part";          
        if(this.questionParts[this.currentStep-1].QuestionType === this.QuestionTypes.WORD_MATCH) this.selectedComponent = "wordmatch-part";          
      }
    },
    currentStepValidation() {
      //TODO : Validation rules
      return true;
    },
    async saveAnswers(){
      if (this.$refs.questionReference) {
        Object.keys(this.$refs.questionReference.$refs).forEach((key) => {          
          if(this.$refs.questionReference.$refs[key].getAttribute("data-id") != null)
          {
            let data = {"QuestionId" : this.$refs.questionReference.$refs[key].getAttribute("data-id"), "Answer" : this.$refs.questionReference.$refs[key].value};
            this.answers.push(data);
            console.log(data);
          }
        });
      }        
    },
    async completeExam() {
      await this.saveAnswers();

      if(!this.isTest) this.sendAnswers();
      
      this.isComplete = true;
    },
    async sendAnswers(){
      let data = {
        SinavGuid: JSON.parse(localStorage.userData).examGuid,
        Cevaplar : this.answers
      };
      await axios
        .create({
          baseURL: "https://eraintranet.erakolejleri.com/API/TestYourSelf",
          headers: { "Content-type": "application/json" },
        })
        .post("/SinavSonucKaydet", data)
        .then(function (response) {
          if (!response.data.Success) 
            alert(response.data.Message);  
          else
            localStorage.clear();                
        });
    },
    async getQuestions() {
      let data = {
        SinavGuid: JSON.parse(localStorage.userData).examGuid,
        IsTest : Number(this.isTest)
      };
      const context = this;
      await axios
        .create({
          baseURL: "https://eraintranet.erakolejleri.com/API/TestYourSelf",
          headers: { "Content-type": "application/json" },
        })
        .post("/SinavSorulariGetir", data)
        .then(function (response) {
          if (!response.data.Success) context.$router.push({ path: "/" });
          
          context.questionParts = JSON.parse(response.data.Data);
          context.lastStep = context.questionParts.length;
        });
    },
    checkUserLogged(){
       if(localStorage.userData)
        if(JSON.parse(localStorage.userData).isLogged)
           return true;

      this.$router.push({ path: "/" });
    }
  },
};
</script>
