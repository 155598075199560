<template>
    <div class="card my-3">
        <div class="card-body bg-success text-white rounded">
          <h5 class="card-title">{{ questionParts.PartType }}</h5>
          <p class="card-text">{{ questionParts.Description }}</p>
        </div>
      </div>
      <div class="row my-4">
        <div class="col-lg-12">
          <audio ref="player" controls>
        <source :src="questionParts.AudioFile" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>          
        </div>
      </div>
      <div class="card my-4" v-for="(question, qIndex) in questionParts.Questions" :key="qIndex">
        <div class="card-body border  rounded">
          <p class="card-text" v-if="question.QuestionContent !== undefined">
            <span class="bg-success text-white font-weight-bold py-1 px-2 mr-8">{{ qIndex + 1 }}.</span>
            {{ question.QuestionContent }}
          </p>
          <div class="form-check form-check-inline" v-for="(choice, cIndex) in question.Choices" :key="cIndex">
        <input class="form-check-input border border-success" :data-id="question.Id"  type="radio" :name="'question' + question.Id"  @change="onChange($event)"  :value="choice"/>
            <label class="form-check-label" :for="'question_' + question.Id + '_choice_' + (cIndex + 1)">{{ choice }}</label>
          </div>  
          <input type="hidden" :ref="'question'+question.Id" :data-id="question.Id" value="">
        </div>
      </div>
</template>

<script>
export default {
  name: "ListeningQuestion",
  components: {
  },
    props: {
    questionParts: {
      type: Object,
    },
  },
mounted: function () { 
        },
  methods: {
          onChange(event) {
            this.$refs[event.target.name].value = event.target.value
          }
      } 
};
</script>
