<template>
  <div class="row mt-5 mx-2">
    <div class="col-lg-12">
      <CustomText tag="h1" class="text-success mb-4 text-center">Test Your Self - Kullanıcı Girişi</CustomText>

      <p class="text-center">Lütfen bekleyiniz...</p>
    </div>
  </div>
</template>

<script>
import CustomText from "@/components/CustomText";
import axios from 'axios'

export default {
  name: "Login",
  components: {
      CustomText
  },
  data : function(){
    return {
      isLogged : false,
      isTest : false
    }
  },
  mounted: function () {
    this.validateUser();
  },  
  methods : {    
    validateUser(){
      const context = this;

      if(this.$route.query.examGuid){ 
        let examGuid = this.$route.query.examGuid;
    
        if(this.$route.query.isTest) this.isTest = true;

        let data = {
          SinavGuid: examGuid,
          IsTest : Number(this.isTest)
        };    
        axios
          .create({
            baseURL: "https://eraintranet.erakolejleri.com/API/TestYourSelf",
            headers: { "Content-type": "application/json" },
          })
          .post("/SinavAtamasiDogrula", data)
          .then(function (response) {
            if (!response.data.Success) 
              alert(response.data.Message);  
            else{
              context.isLogged = true;
              let userData = { "examGuid" : examGuid, "isLogged": context.isLogged, "isTest" : context.isTest};
              context.setUserData(userData);
              context.$router.push({ path: "/exam-parts" });
            }
          });
      }      
    },
    setUserData(data){
        localStorage.userData = JSON.stringify(data);
    }
  }
};
</script>