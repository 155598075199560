
<template>
  <div class="card my-3">
    <div class="card-body bg-success text-white rounded">
      <h5 class="card-title">{{ questionParts.PartType }}</h5>
      <p class="card-text">{{ questionParts.Description }}</p>
    </div>
  </div>
    <div class="card my-4">
    <div class="card-body border  rounded">
      <p class="card-text" v-if="questionParts.ParagraphContent !== undefined" v-html="questionParts.ParagraphContent">
      </p>
        <div v-for="(question, qIndex) in questionParts.Questions" :key="qIndex">
            <div class="input-group input-group-sm border rounded mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text bg-success text-white" id="inputGroup-sizing-sm"
                    >{{qIndex+1}}
                    </span>
                </div>
                <input type="text" class="form-control" :ref="'question'+question.Id" :data-id="question.Id" />
            </div>
        </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "ParagraphQuestion",
  components: {},
  props: {
    questionParts: {
      type: Object,
    },
  },
  mounted() {
  },
  methods: {},
};
</script>
