<template>
  <div class="row mt-5 mx-2">
    <div class="col-lg-12">
      <CustomText tag="h1" class="text-success mb-4">Congratulations!</CustomText>
    </div>
  </div>

</template>

<script>
import CustomText from "@/components/CustomText"; 
export default {
  name: "exam-complete",
  components: {
    CustomText,
  },
};
</script>
