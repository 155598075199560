<template>
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-lg-6">
        <div class="app">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
};
</script>
