<template>
   <div class="row mt-5 mx-2">
    <div class="col-lg-12">
      <CustomText tag="h1" class="text-success mb-4">Exam About</CustomText>

      <table class="table border-success table-bordered">
        <thead>
          <tr class="bg-success text-white font-weight-bold">
            <td>SKILL</td>
            <td class="text-center">PART</td>
            <td class="text-center">POINT</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="part in examParts" :key="part.Index">
            <td>{{ part.skill }}</td>
            <td class="text-center">{{ part.partNumber }}</td>
            <td class="text-center">{{ part.point }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import CustomText from "@/components/CustomText"; 

export default {
  name: "IntroExam",
  props: {
    questionParts: {
      type: Array,
    },
  },
  components: {
    CustomText,
  },
  mounted() {
    this.getExamParts();
  },
  methods: {
    getExamParts()
    {
      this.questionParts.forEach((value,index) => {
        var totalPoint  =  value["Questions"].reduce((acc, item) => acc + item.Point, 0);
        this.examParts.push({skill : value["PartType"],partNumber:index+1,point:totalPoint});
      });
    }
  },
  data: function () {
    return {
      examParts : []
    };
  }
};
</script>
